<script setup lang="ts">
import { useURL } from '~/composables/useURL'
const { setPdfInformations, pdfInformations } = usePdfRaptor()

const config = useRuntimeConfig()
const { t } = useI18n()
const { getBaseURL } = useURL()

const props = withDefaults(
  defineProps<{
    houseId: number
    name: string
    privateToken?: string
    userIsAdmin: boolean
  }>(),
  {
    userIsAdmin: false,
    privateToken: '',
  },
)

const loadingButton = ref({
  preview: false,
  villabook: false,
})
const language = ref({
  preview: 'fr',
  villabook: 'fr',
})
const languages = ref([
  { label: t('global.english'), value: 'en' },
  { label: t('global.french'), value: 'fr' },
])

const villaBookUrl = computed(() => {
  return `${getBaseURL()}/${language.value.villabook}/villabook/${
    props.houseId
  }?private-token=${props.privateToken}`
})
const whiteBrandUrl = computed(() => {
  return `${config.public.whiteBrandUrl}/${language.value.preview}/preview/${props.houseId}?private-token=${props.privateToken}`
})
const downloadPdf = (path: 'preview' | 'villabook') => {
  loadingButton.value[path] = true
  const documentUrl = `${getBaseURL()}/${language.value[path]}/${path}/${props.houseId}`

  setPdfInformations(documentUrl, props.privateToken, props.name)

  setTimeout(() => {
    loadingButton.value[path] = false
  }, 8000)
}
</script>

<template>
  <div class="search-house-download-pdf">
    <h2 class="text-4xl font-bold leading-8">
      {{ $t('houseDownloadPdf.title') }}
    </h2>

    <div class="mt-8">
      <p class="font-bold text-secondary-400">
        {{ $t('houseDownloadPdf.whiteBrandDocuments') }}
      </p>

      <div
        class="flex flex-col w-full md:grid md:grid-cols-3 md:gap-4 items-center"
      >
        <LazyBaseFormComponentsBaseMultiselect
          v-model="language.preview"
          :can-clear="false"
          :options="languages"
          open-direction="top"
          name="language-whitebrand"
          input-class="w-full"
          input-label="name"
          wrapper-class="w-full"
        />
        <base-button
          :disabled="loadingButton.preview"
          :loader="loadingButton.preview"
          class="w-full my-2 md:my-0"
          color="secondary"
          variant="outline"
          @click="downloadPdf('preview')"
        >
          <span class="font-bold">{{ $t('global.download') }}</span>
        </base-button>
        <base-button
          class="w-full"
          color="secondary"
          target="_blank"
          :href="whiteBrandUrl"
        >
          <span class="font-bold">{{ $t('houseDownloadPdf.seeOnline') }}</span>
        </base-button>
      </div>
    </div>

    <div v-if="userIsAdmin" class="mt-8">
      <p class="font-bold text-secondary-400">
        {{ $t('houseDownloadPdf.villaBookDocuments') }}
      </p>

      <div
        class="flex flex-col w-full md:grid md:grid-cols-3 md:gap-4 items-center"
      >
        <LazyBaseFormComponentsBaseMultiselect
          v-model="language.villabook"
          :can-clear="false"
          :options="languages"
          open-direction="top"
          input-class="w-full"
          input-label="name"
          name="language-villabook"
          wrapper-class="w-full"
        />
        <base-button
          :disabled="loadingButton.villabook"
          :loader="loadingButton.villabook"
          class="w-full mt-2 md:mt-0"
          color="secondary"
          variant="outline"
          @click="downloadPdf('villabook')"
        >
          <span class="font-bold">{{ $t('global.download') }}</span>
        </base-button>
        <base-button
          class="w-full"
          color="secondary"
          target="_blank"
          :href="villaBookUrl"
        >
          <span class="font-bold">{{ $t('houseDownloadPdf.seeOnline') }}</span>
        </base-button>
      </div>
    </div>
    <PdfRaptor :download-informations="pdfInformations" />
  </div>
</template>

<style>
.search-house-download-pdf .base-multiselect {
  @apply w-full;
}
</style>
