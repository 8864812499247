import {
  InputTextarea,
  InputSelect,
} from '~/components/BaseForm/core/primitives'

import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const subject: typeof InputSelect = {
    ...InputSelect,
    attr: {
      ...InputSelect.attr,
      labelTextTranslateKey: 'field.subject',
      name: 'subject',
      rules: 'required',
      placeholderTranslateKey: 'field.placeholder.selectReason',
    },
    containerInputClass: 'mb-4',
    options: [
      {
        value: 'contract_informations',
        labelTranslateKey: 'houseRequest.reasons.contactInformation',
      },
      {
        value: 'general_informations',
        labelTranslateKey: 'houseRequest.reasons.generalInformation',
      },
      {
        value: 'included_services',
        labelTranslateKey: 'houseRequest.reasons.servicesIncluded',
      },
      {
        value: 'ical',
        labelTranslateKey: 'houseRequest.reasons.iCal',
      },
      {
        value: 'short_stay',
        labelTranslateKey: 'houseRequest.reasons.shortStay',
      },
      {
        value: 'covid_conditions',
        labelTranslateKey: 'houseRequest.reasons.covid',
      },
    ],
  }

  const notes: typeof InputTextarea = {
    ...InputTextarea,
    attr: {
      ...InputTextarea.attr,
      rules: 'required',
      labelTranslateKey: '',
      placeholderTranslateKey: 'field.notes',
      rows: 4,
      name: 'notes',
    },
    containerInputClass: 'w-full',
  }

  return {
    fields: [subject, notes],
  }
}
