<script setup lang="ts">
import { createListFilters } from '~/helpers/search/createListFilters'
import type { InputSearchQuery, NumericFilter } from '~/types/search/types'

const { isSmallScreen } = useBreakpoint()

const emits = defineEmits<{
  'clear-filter-numeric': [NumericFilter]
  'clear-query': []
  'click-proposal-button': []
  'dates-cleared': []
  'refine-by-query': [InputSearchQuery]
  'refine-filter-numeric': [NumericFilter, number]
  'remove-option': [Partial<InputSearchQuery>]
  'toggle-filters-modal': []
  'trigger-click': []
  'update-budget': [string, string]
  'update:end-date': [Date]
  'update:start-date': [Date]
}>()

withDefaults(
  defineProps<{
    bathrooms: number
    bedrooms: number
    capacity: number
    endDate: string | null
    isLoading: boolean
    maxBudget: string
    minBudget: string
    queryValues: InputSearchQuery[]
    startDate: string | null
  }>(),
  {
    bathrooms: 0,
    bedrooms: 0,
    capacity: 0,
    endDate: null,
    isLoading: false,
    maxBudget: '1000000',
    minBudget: '0',
    queryValues: () => [],
    startDate: null,
  },
)

const numericFiltersOptions = createListFilters(52)
const clearFilterNumeric = (type: NumericFilter) => {
  emits('clear-filter-numeric', type)
}
const refineByQuery = (option: InputSearchQuery) => {
  emits('refine-by-query', option)
}
const refineFilterNumeric = (attribute: NumericFilter, value: number) => {
  emits('refine-filter-numeric', attribute, value)
}
const updateBudget = (maxBudget: string, minBudget: string) => {
  emits('update-budget', maxBudget, minBudget)
}
</script>

<template>
  <div
    v-if="!isSmallScreen"
    class="search-top-bar-partner py-4 px-6 shadow-normal sticky top-0 z-sticky bg-white"
  >
    <SearchInput
      class="mx-1 w-full"
      is-taggable
      :disabled="isLoading"
      :model-value="queryValues"
      @clear-query="emits('clear-query')"
      @refine-by-query="refineByQuery"
      @remove-option="emits('remove-option', $event)"
    />

    <div class="flex items-center mt-2">
      <div class="flex grow items-center">
        <LazyBaseFormComponentsBaseCalendar
          ref="searchCalendarAdmin"
          disabled-days-before-day-date
          name="searchCalendar"
          has-footer
          wrapper-class="mx-1 w-1/2 xl:w-[315px] mb-0"
          :check-in="startDate"
          :check-out="endDate"
          :disabled="isLoading"
          :placeholder="{
            checkIn: $t('calendar.check_in'),
            checkOut: $t('calendar.check_out'),
          }"
          @clear-dates="emits('dates-cleared')"
          @update:end-date="emits('update:end-date', $event)"
          @update:start-date="emits('update:start-date', $event)"
        >
          <template #lc-calendar-footer="{ clearDates }">
            <button
              :class="[
                'text-md font-bold underline ml-auto',
                {
                  'text-gray-400 cursor-not-allowed': !startDate && !endDate,
                },
                { 'text-gray-700': startDate || endDate },
              ]"
              :disabled="!startDate && !endDate"
              type="button"
              @click="clearDates"
            >
              {{ $t('inquiry.clearDates') }}
            </button>
          </template>
        </LazyBaseFormComponentsBaseCalendar>

        <SearchBudget
          class="mr-1 xl:mx-1 w-1/4 xl:w-auto"
          :disabled="isLoading"
          :max-budget="maxBudget"
          :min-budget="minBudget"
          @update-budget="updateBudget"
        />

        <SearchFilterSelect
          attribute="capacity"
          class="mx-1 w-1/4 xl:w-auto"
          icon-name="group"
          wrapper-class="w-full"
          :disabled="isLoading"
          :list="numericFiltersOptions"
          :model-value="capacity"
          @clear="clearFilterNumeric('capacity')"
          @refine="refineFilterNumeric"
          @trigger-click="emits('trigger-click')"
        />
        <SearchFilterSelect
          attribute="bedrooms"
          class="mx-1 w-1/4 xl:w-auto"
          icon-name="bed"
          wrapper-class="w-full"
          :disabled="isLoading"
          :list="numericFiltersOptions"
          :model-value="bedrooms"
          @clear="clearFilterNumeric('bedrooms')"
          @refine="refineFilterNumeric"
          @trigger-click="emits('trigger-click')"
        />
        <SearchFilterSelect
          attribute="bathrooms"
          class="mx-1 w-1/4 xl:w-auto"
          icon-name="bathroom"
          wrapper-class="w-full"
          :disabled="isLoading"
          :list="numericFiltersOptions"
          :model-value="bathrooms"
          @clear="clearFilterNumeric('bathrooms')"
          @refine="refineFilterNumeric"
          @trigger-click="emits('trigger-click')"
        />
      </div>
    </div>
  </div>

  <SearchTopBarMobile
    v-else
    :end-date="endDate"
    :is-loading="isLoading"
    :max-budget="maxBudget"
    :min-budget="minBudget"
    :multipe-query="true"
    :query-value="queryValues"
    :start-date="startDate"
    @clear-query="emits('clear-query')"
    @dates-cleared="emits('dates-cleared')"
    @refine-by-query="refineByQuery"
    @toggle-filters-modal="emits('toggle-filters-modal')"
    @update-budget="updateBudget"
    @update:end-date="emits('update:end-date', $event)"
    @update:start-date="emits('update:start-date', $event)"
    @remove-option="emits('remove-option', $event)"
  />
</template>

<style>
.search-top-bar-partner .search-filter .multiselect-wrapper {
  @apply justify-start;
}

.search-top-bar-partner .search-filter .multiselect-placeholder,
.search-top-bar-partner .search-filter .multiselect-single-label {
  @apply static	px-2;
}
</style>
