<script setup lang="ts">
withDefaults(
  defineProps<{
    bathrooms: number
    bedrooms: number
    capacity: number
    destination: string
    firstPhotoUrl: string
    houseId: number
    modelValue?: boolean
    name: string
  }>(),
  {
    modelValue: false,
  },
)
const emits = defineEmits<{
  'close-modal': []
  'update:model-value': []
}>()

const attrToString = (attr: number) => attr.toString()
</script>

<template>
  <SearchAdminModal
    :destination="destination"
    :first-photo-url="firstPhotoUrl"
    :model-value="modelValue"
    :name="name"
    @close-modal="emits('update:model-value')"
  >
    <template #image-bottom-right>
      <div v-if="capacity" class="flex items-center">
        <BaseTag
          v-if="capacity"
          :text="attrToString(capacity)"
          class="flex items-center justify-between mr-2 text-white"
          icon="group"
        />
        <BaseTag
          v-if="bedrooms"
          :text="attrToString(bedrooms)"
          class="flex items-center justify-between mr-2 text-white"
          icon="bed"
        />
        <BaseTag
          v-if="bathrooms"
          :text="attrToString(bathrooms)"
          class="flex items-center justify-between mr-2 text-white"
          icon="bathroom"
        />
      </div>
    </template>

    <template #content>
      <SearchAdminHouseRequestForm
        :house-id="houseId"
        @canceled="emits('close-modal')"
      />
    </template>
  </SearchAdminModal>
</template>
