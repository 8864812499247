import type { Locale } from 'lc-services/types'
import type {
  StateSearchResults,
  SearchPartnerHouse,
} from '~/types/search/types'

import { transformPrice } from '~/helpers/priceHelper'

export const useSearchPartnerResults = ({
  hasDates,
}: {
  hasDates: Ref<boolean>
}) => {
  const stateSearchResults = useState<StateSearchResults>(
    'state-search-results',
  )
  const { t, locale } = useI18n()
  const { currency } = useUser()

  const formattedHits = computed<SearchPartnerHouse[]>(() => {
    return stateSearchResults.value.hits.map((hit) => {
      const isValidPeriod =
        (hit?.fullyCovered && hit?.allMinimumDurationValid) || false

      const currentPrice = transformPrice({
        currency: currency.value,
        hasDates: hasDates.value,
        hit,
        i18n: {
          locale,
          t,
        },
        isValidPeriod,
        userIsAdmin: false,
        userIsPartner: true,
      })

      return {
        bathrooms: hit.bathrooms,
        bedrooms: hit.bedrooms,
        capacity: hit.capacity,
        commissionRateB2b2c: hit.commission_rate_b2b2c,
        currentPrice,
        definitivePrices: hit.definitivePrices,
        destination: hit.destination[locale.value as Locale],
        displayPrices: hit.display_prices,
        firstPhotoUrl: hit.first_photo_url,
        iconicCollection: hit.iconic_collection,
        id: hit.id,
        name: hit.name,
        photos: hit.photos,
        privateToken: hit.private_token,
        slug: hit.slug[locale.value as Locale],
      }
    })
  })

  return {
    formattedHits,
  }
}
