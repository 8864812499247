import type { Locale } from 'lc-services/types'
import { getDestinationHousesCount } from '~/utils/algolia/services'

const formatDestination = (
  data: { clusterizedName: { fr: string; en: string }; id: string },
  locale: Locale,
) => {
  return {
    name: data.clusterizedName[locale],
    id: Number(data.id),
  }
}

export const getDestinations = async ({
  $api,
  destinationId,
  locale,
}: {
  $api: unknown
  destinationId: string | string[]
  locale: Locale
}) => {
  try {
    const fields = {
      destination: ['id', 'clusterizedName'],
    }
    const res = await $api.v3.destination.getById({
      id: destinationId,
      fields,
    })

    if (res?.data) {
      if (Array.isArray(res.data)) {
        return res.data.map((data) => formatDestination(data, locale))
      }

      return [formatDestination(res.data, locale)]
    }
  } catch {
    return []
  }
}

export const constructFilters = (apiDestinations) => {
  const ids = apiDestinations
    .filter((destination) => Boolean(destination.coverPhotoUrl))
    .map((destination) => destination.id)
  const filtersDestiIds = ids.length
    ? ids.map((id) => `id=${id}`).join(' OR ')
    : ''
  let filters = 'NOT houses_count=0 AND NOT state:hidden'

  if (ids.length) {
    filters += ` AND ${filtersDestiIds}`
  }

  return filters
}

// Order destinations with featured boolean and position
export const orderDestinationsBlock = async (
  apiDestinations,
  strapiDestinations,
  config,
) => {
  const filters = constructFilters(apiDestinations)
  const hitsHouses = await getDestinationHousesCount({
    config,
    filters,
    query: '',
  })

  const destinations = apiDestinations
    .filter((destination) => Boolean(destination.coverPhotoUrl))
    .map((destination) => {
      const strapiDestination = strapiDestinations.find(
        (x) => Number(x.destinationId) === Number(destination.id),
      )
      const housesCount =
        hitsHouses?.hits?.find(
          (hit) => Number(hit.id) === Number(destination.id),
        )?.houses_count || 0

      if (strapiDestination) {
        return {
          ...destination,
          housesCount,
          isFeatured: strapiDestination.isFeatured,
          position: strapiDestination.position,
        }
      }

      return {}
    })
  const orderedDestinations = destinations.sort((a, b) =>
    a.isFeatured === b.isFeatured
      ? a.position - b.position
      : a.isFeatured
        ? -1
        : 1,
  )

  return orderedDestinations
}
