<script setup lang="ts">
import { getDestinations } from '~/helpers/search/getDestinations'
import { $dayjs } from '@/utils/dayjs'

import type { Locale } from 'lc-services/types'
import type {
  InputSearchQuery,
  NumericFilter,
  QueryModelType,
  RefetchResultsKeys,
  ReplicasValues,
  SearchAdminHouse,
  SearchFacetObject,
  SearchFacetValue,
} from '~/types/search/types'

import type { StateProposal } from '~/types/proposal'
import type { StateDestination } from '~/types/types'

const { isSmallScreen, isBigScreen, isDesktopOrTablet } = useBreakpoint()
const { t, locale } = useI18n()
const { $api } = useNuxtApp()

$dayjs.locale(locale.value)

const stateDestination = useState<StateDestination>('destination')
const destination = stateDestination.value?.destination || {}
const disableQuery = stateDestination.value?.disableQuery || false
const isIndexesFilter = stateDestination.value?.isIndexesFilter || false
let destinationId = stateDestination.value?.destinationId || null
const queryOutput = stateDestination.value?.queryOutput || {}
const localePath = useLocalePath()
const route = useRoute()
const router = useRouter()
const { headerNavigation } = useHeaderNavigation()
const { setFiltersCount } = useSearchFiltersCount()
const { currency } = useUser()

const showModalProposalCreate = ref(false)
const isLoading = ref(false)
const mapVisible = ref(false)
const searchContainer = ref<HTMLElement | null>(null)
const showFiltersModal = ref(false)

const {
  bathrooms,
  bedrooms,
  capacity,
  setNumericFilter,
  clearAllNumericFilters,
  clearNumericFilterByType,
} = useSearchNumericFilters()

const {
  newFacetFilters,
  facetsFiltersParams,
  facetFilters,
  clearAllFacetFilters,
  setFacetFilter,
} = useSearchFacetFilters()

const {
  clearDateFilters,
  setDateStart,
  setDateEnd,
  startDate,
  endDate,
  hasDates,
} = useSearchDateFilters()

const { minBudget, maxBudget, price, clearBudgetFilters, setBudgetFilters } =
  useSearchBudgetFilters()

const { currentReplica, replicas, setReplicaFilters } =
  useSearchReplicaFilters()

const {
  queryValueMultiple,
  apiParamsDestinationIds,
  fetchParentLocation,
  clearMultipleLocationsFilters,
  setMultipleLocations,
  queryDestinationIds,
  queryHouseIds,
} = useSearchLocationFilters()

const {
  facets,
  fetchHouseMapResults,
  fetchHouseResults,
  hasOnlyInvalidResults,
  hitsMap,
  keepOldResults,
  housesPerPage,
  initClientSide,
  invalidHouses,
  nbHits,
  nbPages,
  page,
  resetRefetchProperties,
  setPage,
  setKeepOldResults,
  showPreviousButton,
} = useSearchResults({ startDate, endDate })

const { formattedHits } = useSearchAdminResults({ hasDates })

const { showMapButton } = useSearchMap(searchContainer, mapVisible)
const { fetchUserProposal } = useUserProposal()
const { addHouseToProposal, removeHouseToProposal, houseExistInProposal } =
  useUserProposalB2c()

const { currentHouseFavoriteSelected, setCurrentHouseFavorite } =
  useSearchWishlist()
const { updateRouter } = useSearchUpdateRouter()
const { isExclusivityFilter } = useSearchFiltersExclusivity()

const searchHousesPayload = computed(() => {
  const payload: QueryModelType = {
    initializelist: !keepOldResults.value || !initClientSide.value,
    mode: 'admin',
    bathrooms: bathrooms.value ? bathrooms.value.toString() : '1',
    bedrooms: bedrooms.value ? bedrooms.value.toString() : '1',
    capacity: capacity.value ? capacity.value.toString() : '1',
    itemsPerPage: housesPerPage.value,
    replica: currentReplica.value.label,
    algoliaFilters: isExclusivityFilter.value ? 'AND exclusivity=1' : '',
  }

  if (apiParamsDestinationIds.value.length) {
    payload.destinationIds = apiParamsDestinationIds.value
  }
  if (page.value > 0) {
    payload.page = page.value
  }
  if (newFacetFilters.value?.size) {
    payload.facetFilters = newFacetFilters.value
  }
  if (price.value.length) {
    payload.prices = price.value
  }
  if (startDate.value && endDate.value) {
    payload.period = {
      startDate: startDate.value,
      endDate: endDate.value,
    }
  }
  if (queryHouseIds.value.length) {
    payload.housesIds = queryHouseIds.value
  }

  return payload
})

const searchDestination = computed(
  () => headerNavigation.value.searchDestination,
)
const showPaginate = computed(() => nbPages.value > page.value + 1)
const hasResults = computed(() => Boolean(nbHits.value))
const numberPlaceholderProducts = computed(() =>
  isDesktopOrTablet.value ? 6 : 2,
)

const searchResultsText = computed(() => {
  if (
    mapVisible.value &&
    hitsMap.value.length > 0 &&
    startDate.value &&
    endDate.value
  ) {
    return t('search.results', hitsMap.value.length)
  }

  return t('search.results', nbHits.value)
})

await useAsyncData('search-admin-async-data', async () => {
  useSearchInitStates({
    typeSearch: 'admin',
    destination,
    userCurrency: currency.value,
    queryOutput,
  })

  if (import.meta.client) {
    setKeepOldResults(false)
    setPage(0)
  }

  await Promise.all([
    fetchHouseResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    }),
    fetchUserProposal(),
  ])

  //
  // prefill search Input
  let destinations: { name: string; id: number }[] = []
  if (queryDestinationIds.value.length) {
    const res = await getDestinations({
      $api,
      destinationId: queryDestinationIds.value,
      locale: locale.value as Locale,
    })

    destinations = res as { name: string; id: number }[]
  }

  const locationDestinations =
    destinations?.map<InputSearchQuery>((d) => ({
      value: d.id,
      label: d.name,
      type: 'location' as const,
    })) || []

  const locationHits = formattedHits.value
    .filter((f) => queryHouseIds.value.includes(String(f.id)))
    .map<InputSearchQuery>((h) => ({
      value: h.id,
      label: h.name,
      type: 'type-of-house' as const,
    }))

  const newLocations = [...locationDestinations, ...locationHits]
  if (destination.id) {
    const currentDestination: InputSearchQuery = {
      value: Number(destination.id),
      label: destination.name,
      type: 'location' as const,
    }

    newLocations.unshift(currentDestination)
  }

  setMultipleLocations(newLocations)
  // end prefill search Input
  //

  return true
})

// Watch
watch(
  () => currency.value,
  async (_) => {
    await refetchResultsFrom('currency')
  },
)

watch(
  () => showFiltersModal.value,
  (newValue) => {
    if (newValue) {
      window.scrollTo(0, 0)
    }
  },
)

// Watch when header search click on desti
watch(
  () => searchDestination.value,
  async (
    newValue: { name: { en: string; fr: string }; destinationId: number },
    _,
  ) => {
    if (newValue) {
      const fakeSelectedOption: InputSearchQuery = {
        label: newValue.name[locale.value as Locale],
        slug: '',
        type: 'location',
        value: newValue.destinationId,
      }

      await refineByQuery(fakeSelectedOption)
    }
  },
)

// Methods
const clearAllFilterModalV2 = async () => {
  clearAllFacetFilters()
  clearAllNumericFilters()

  await refetchResultsFrom('clearAll')
}

const clearFilters = async () => {
  clearBudgetFilters()
  clearAllFacetFilters()
  clearAllNumericFilters()
  clearDateFilters()

  await refetchResultsFrom('clearAll')
}

const clearQuery = async () => {
  destinationId = ''
  clearMultipleLocationsFilters()

  await refetchResultsFrom('clearQuery')
}

const clearFilterNumeric = async (type: NumericFilter) => {
  clearNumericFilterByType(type)

  await refetchResultsFrom('clearFilterNumeric')
}

const toggleMap = async () => {
  window.scrollTo(0, 0)

  if (!mapVisible.value) {
    await refetchResultsFrom('map')

    mapVisible.value = true
  } else {
    mapVisible.value = false
  }
}

const filteredNumeric = async (
  attribute: NumericFilter,
  selectedFilter: number,
) => {
  setNumericFilter(attribute, selectedFilter)
  await refetchResultsFrom(`numericFilters-${attribute}`)
}

const paginate = async (
  pageDirection: 'next-page' | 'previous-page' = 'next-page',
) => {
  // we need to set previousPage and nextPage so that if multiple pages are already visible we don't duplicate content
  if (pageDirection === 'previous-page') {
    setPage(page.value - 1)
  } else {
    setPage(page.value + 1)
  }

  await refetchResultsFrom(pageDirection)

  window.scrollTo(0, window.scrollY)
}

const refineByQuery = async (selectedOption: InputSearchQuery) => {
  const newLocation: InputSearchQuery = {
    label: selectedOption.label,
    value: selectedOption.value,
    type: selectedOption.type,
  }

  setMultipleLocations([...queryValueMultiple.value, newLocation])

  await refetchResultsFrom('query')
}

const refineByExclu = async () => {
  await refetchResultsFrom('excluFilters')
}

const clearFacetsOther = async () => {
  clearAllFacetFilters()

  await refetchResultsFrom('facets')
}

const refineFacets = async (
  facet: SearchFacetObject<SearchFacetValue<string>[]>,
  facetValue: { checked: boolean; value: string },
) => {
  facetValue.checked = !facetValue.checked

  setFacetFilter(facet, facetValue)

  await refetchResultsFrom('facets')
}

const datesCleared = async () => {
  clearDateFilters()

  await refetchResultsFrom('dates')
}

const handleSetDatesStart = (date: Date) => {
  setDateStart(date)
}

const handleSetDatesEnd = (date: Date) => {
  setDateEnd(date)
  setDates()
}

const setDates = async () => {
  if (startDate.value && endDate.value) {
    await refetchResultsFrom('dates')
  }
}

const sortByReplica = async (replica: {
  label: ReplicasValues
  value: string
}) => {
  setReplicaFilters(replica)

  await refetchResultsFrom('replica')
}

const updateBudget = async (maxB: string, minB: string) => {
  setBudgetFilters(minB, maxB)

  await refetchResultsFrom('budget')
}

const triggerClick = () => {
  searchContainer.value?.click()
}

const showHouseCardInfo = (houseId: number) => {
  const twoCardsPerLine =
    !isBigScreen.value && invalidHouses.value.first === houseId
  const threeCardsPerLine =
    isBigScreen.value && invalidHouses.value.second
      ? Number(invalidHouses.value.second) === Number(houseId)
      : Number(invalidHouses.value.first) === Number(houseId)

  return threeCardsPerLine || twoCardsPerLine
}

const toggleFiltersModal = () => {
  showFiltersModal.value = !showFiltersModal.value
}

onBeforeMount(() => {
  if (!showPreviousButton.value && page.value > 1) {
    const query = route.query

    delete query.page
    router.push({ query: { ...query } })
  }
})

const refetchResultsFrom = async (from: RefetchResultsKeys) => {
  resetRefetchProperties(from)

  isLoading.value = true

  if (from === 'map' || mapVisible.value) {
    await fetchHouseMapResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })
  } else {
    await fetchHouseResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })
  }

  isLoading.value = false

  // No result
  if (formattedHits.value.length === 0 && destinationId) {
    await fetchParentLocation(Number(destinationId))
    destinationId = null
    await refetchResultsFrom('getParentDestiHits')
  }

  updateRouter(isIndexesFilter, disableQuery)
}

if (
  route?.name?.toString()?.includes('destination-slug-filter') &&
  nbHits.value === 0
) {
  const path = localePath({
    name: 'destination-name',
    params: { name: route.params.slug },
  })

  navigateTo(path, { redirectCode: 301 })
}

onMounted(() => {
  setFiltersCount(countTotalFiltersSelected.value)
})

// Count selected filters
const countTotalFiltersSelected = computed(() => {
  let total = 0

  if (capacity.value) total += 1
  if (bathrooms.value) total += 1
  if (bedrooms.value) total += 1

  total += facetsFiltersParams.value.length || 0

  return total
})

watch(
  () => countTotalFiltersSelected.value,
  (val) => {
    setFiltersCount(val)
  },
)

// ADMIN
const selectedAdminHouse = ref<SearchAdminHouse | null>(null)
const adminHouseRequestModal = ref(false)
const houseDownloadPdfModal = ref(false)

const openModalDownloadPdf = (house: SearchAdminHouse) => {
  selectedAdminHouse.value = house
  houseDownloadPdfModal.value = true
}
const openModalAdminHouseRequest = (house: SearchAdminHouse) => {
  selectedAdminHouse.value = house
  adminHouseRequestModal.value = true
}

const closeModalDownloadPdf = () => {
  selectedAdminHouse.value = null
  houseDownloadPdfModal.value = false
}
const closeModalAdminHouseRequest = () => {
  selectedAdminHouse.value = null
  adminHouseRequestModal.value = false
}

const addHouseToProposalIsHover = ref<Record<number, boolean>>({})
const proposalState = useState<StateProposal>('proposal')
const generateProposalHouseId = () => Date.now()

const proposalDisabled = computed(() => {
  return !hasDates.value || !proposalState.value.id
})

const proposalDisabledText = computed(() => {
  if (!proposalState.value.id)
    return t('stayProposal.search.selectDealToCreateProposal')
  if (!hasDates.value)
    return t('stayProposal.search.selectDatesToCreateProposal')

  return ''
})

const removeSearchInputOption = async (
  removedOption: Partial<InputSearchQuery>,
) => {
  const locations = queryValueMultiple.value.filter(
    (q) => !(q.type === removedOption.type && q.value === removedOption.value),
  )

  setMultipleLocations(locations)
  await refetchResultsFrom('clearQuery')
}
</script>

<template>
  <div class="w-full mb-10">
    <div ref="searchContainer">
      <div
        v-if="isLoading"
        class="fixed bg-gray-200 bg-opacity-90 top-0 left-0 right-0 z-fixed"
      />

      <SearchTopBarAdmin
        :end-date="endDate"
        :capacity="capacity"
        :bedrooms="bedrooms"
        :bathrooms="bathrooms"
        :is-loading="isLoading"
        :max-budget="maxBudget"
        :min-budget="minBudget"
        :query-values="queryValueMultiple"
        :start-date="startDate"
        @clear-filter-numeric="clearFilterNumeric"
        @clear-query="clearQuery"
        @dates-cleared="datesCleared"
        @refine-by-query="refineByQuery"
        @refine-filter-numeric="filteredNumeric"
        @toggle-filters-modal="showFiltersModal = true"
        @trigger-click="triggerClick"
        @update:end-date="handleSetDatesEnd"
        @update:start-date="handleSetDatesStart"
        @update-budget="updateBudget"
        @click-proposal-button="showModalProposalCreate = true"
        @remove-option="removeSearchInputOption"
      />

      <SearchNoResult v-if="!hasResults" @clear-refinements="clearFilters">
        <SearchWaitingListCard
          v-if="destinationId"
          :banner="!$device.isMobile"
          :bathrooms="bathrooms"
          :bedrooms="bedrooms"
          :capacity="capacity"
          :currency="currency"
          :destination-id="Number(destinationId)"
          :end-date="endDate"
          :max-budget="maxBudget"
          :min-budget="minBudget"
          :start-date="startDate"
          class="mt-12"
        />
      </SearchNoResult>

      <div
        :class="[
          'mt-4',
          { flex: !isSmallScreen },
          { 'px-4': isSmallScreen, 'px-6': !isSmallScreen },
        ]"
      >
        <div
          v-if="!isSmallScreen && hasResults"
          class="w-3/12 sticky h-full top-[calc(142px+1rem)] xl:top-[calc(142px+1rem)]"
          data-cy="search-sidebar-filter"
        >
          <SearchFilterSidebar
            class="mr-4"
            :facet-filters="facetFilters"
            :facets="facets"
            :loading-facets="isLoading"
            @changeFacet="refineFacets"
            @clearFacetsOther="clearFacetsOther"
            @changeExclu="refineByExclu"
          />
        </div>

        <div
          :class="{
            'w-9/12': !isSmallScreen && hasResults,
            'w-full': !hasResults,
          }"
        >
          <div>
            <BaseRow v-if="hasResults" id="search-results">
              <BaseCol cols="24">
                <SearchResultHeader
                  :current-replica="currentReplica.value"
                  :is-loading="isLoading"
                  :map-visible="mapVisible"
                  :replicas="replicas"
                  :search-results-text="searchResultsText"
                  show-btn-map-and-sort
                  @change-replica="sortByReplica"
                  @toggle-map="toggleMap"
                />

                <template v-if="!mapVisible">
                  <SearchListLoadPreviousButton
                    v-if="showPreviousButton"
                    :page="page"
                    @previous-page="paginate('previous-page')"
                  />

                  <BaseRow v-if="isLoading" class="mb-6">
                    <BaseCol
                      v-for="n in numberPlaceholderProducts"
                      :key="`base-house-card-placeholder-${n}`"
                      class="mb-4"
                      cols="24"
                      md="12"
                      xxl="8"
                    >
                      <BaseHouseCardPlaceholder />
                    </BaseCol>
                  </BaseRow>

                  <BaseRow class="mb-2">
                    <template
                      v-for="house in formattedHits"
                      :key="`house-${house.id}`"
                    >
                      <template
                        v-if="
                          !isLoading &&
                          startDate &&
                          endDate &&
                          invalidHouses.first === house.id
                        "
                      >
                        <h2
                          :key="`text-${house.id}`"
                          :class="[
                            'px-4 mb-8 text-4xl w-24/24',
                            {
                              'mt-8': hasOnlyInvalidResults,
                              'mt-16': !hasOnlyInvalidResults,
                            },
                          ]"
                        >
                          {{
                            $t(
                              'search.invalidPeriodHouses.otherAvailableHouses',
                            )
                          }}
                        </h2>
                      </template>

                      <BaseCol class="mb-6 md:mb-4" cols="24" md="12" xxl="8">
                        <SearchHouseCard
                          :id="house.id"
                          :bathrooms="house.bathrooms"
                          :bedrooms="house.bedrooms"
                          :capacity="house.capacity"
                          :clickable="false"
                          :destination="house.destination"
                          :photos="house.photos"
                          :slug="house.slug"
                          :title="house.name"
                        >
                          <template v-if="!proposalState.b2b2c" #card-action>
                            <button
                              v-if="
                                houseExistInProposal({
                                  checkInDate: startDate,
                                  checkOutDate: endDate,
                                  id: house.id,
                                })
                              "
                              aria-label="remove"
                              class="flex items-center justify-center bg-primary-800 w-[40px] h-[40px] rounded-full"
                              data-cy="proposal-remove-house"
                              type="button"
                              @click.prevent.stop="
                                removeHouseToProposal({
                                  checkInDate: startDate,
                                  checkOutDate: endDate,
                                  id: house.id,
                                  proposalHouseId: Number(proposalState.id),
                                })
                              "
                            >
                              <BaseIcon
                                name="check"
                                :size="1.5"
                                color="text-white"
                              />
                            </button>
                            <BaseTooltip
                              v-else
                              content-class="!w-60"
                              position="left"
                              rounded
                              show
                              size="small"
                            >
                              <template #element>
                                <button
                                  :class="[
                                    'flex items-center justify-center bg-beige w-[40px] h-[40px] rounded-full',
                                    {
                                      'cursor-not-allowed bg-gray-100':
                                        proposalDisabled,
                                    },
                                  ]"
                                  :disabled="proposalDisabled"
                                  aria-label="add"
                                  data-cy="proposal-add-house"
                                  type="button"
                                  @click.prevent.stop="
                                    addHouseToProposal({
                                      checkInDate: startDate,
                                      checkOutDate: endDate,
                                      destination: house.destination,
                                      destiId: house.destinationId,
                                      id: house.id,
                                      name: house.name,
                                      photo: house.firstPhotoUrl,
                                      price: Number(house.currentPrice.value),
                                      proposalHouseId:
                                        generateProposalHouseId(),
                                      sales_comment: null,
                                    })
                                  "
                                  @mouseover="
                                    addHouseToProposalIsHover[house.id] = true
                                  "
                                  @mouseleave="
                                    addHouseToProposalIsHover[house.id] = false
                                  "
                                >
                                  <BaseIcon
                                    :name="
                                      addHouseToProposalIsHover[house.id] &&
                                      !proposalDisabled
                                        ? 'check'
                                        : 'plus'
                                    "
                                    :size="1.5"
                                  />
                                </button>
                              </template>

                              <template v-if="proposalDisabled" #text>
                                {{ proposalDisabledText }}
                              </template>
                            </BaseTooltip>
                          </template>
                          <template #card-media>
                            <div
                              class="absolute top-0 bottom-0 w-full overflow-hidden"
                            >
                              <BaseNuxtImg
                                :alt="house.name"
                                :src="house.firstPhotoUrl"
                                class="group-hover:scale-[1.05] duration-500 ease-in-out"
                                sizes="sm:100vw md:50vw lg:50vw xl:25vw xxl:25vw"
                              />
                            </div>
                            <SearchAdminHouseDetailLinks
                              :house-id="house.id"
                              :private-token="house.privateToken"
                              :slug="house.slug"
                              class="absolute z-10 items-center w-full h-full transition-opacity duration-300 transform -translate-y-1/2 opacity-0 hover:opacity-70 bg-gray-700 top-1/2"
                              color="white"
                              @handle-download-pdf="openModalDownloadPdf(house)"
                              @handle-send-comment="
                                openModalAdminHouseRequest(house)
                              "
                            />
                            <div
                              class="flex absolute left-0 bottom-2 z-10 px-4"
                            >
                              <div
                                :class="[
                                  'font-sansSerif font-medium text-sm py-1 px-3 text-white rounded-full',
                                  {
                                    'bg-secondary-200':
                                      house.state === 'published',
                                    'bg-error': house.state === 'hidden',
                                  },
                                ]"
                              >
                                {{ $t(`search.houseStatus.${house.state}`) }}
                              </div>

                              <BaseTooltip
                                v-if="house.hasSalesOptions"
                                content-class="!w-60 !bottom-15 rounded"
                                position="top"
                                rounded
                                show
                                clickable
                                size="small"
                                variant="white"
                              >
                                <template #element>
                                  <div
                                    class="cursor-pointer font-sansSerif font-medium text-sm py-1 px-3 ml-2 text-white rounded-full bg-[#BA2666]"
                                  >
                                    {{ t(`search.houseStatus.salesOption`) }}
                                  </div>
                                </template>
                                <template v-if="house.hasSalesOptions" #text>
                                  <div class="line-clamp-3">
                                    <div class="font-bold text-gray-600">
                                      {{ t(`search.houseStatus.salesOption`) }}
                                    </div>
                                    {{
                                      house.currentSalesOptionDates.checkInDate
                                    }}
                                    -
                                    {{
                                      house.currentSalesOptionDates.checkOutDate
                                    }}
                                  </div>
                                  <div class="font-bold text-primary-700">
                                    {{ house.currentSalesOptionOwner }}
                                  </div>
                                </template>
                              </BaseTooltip>

                              <BaseTooltip
                                v-if="house.warning"
                                content-class="!w-60 !top-[calc(100%+20px)] rounded"
                                position="bottom"
                                rounded
                                show
                                size="small"
                              >
                                <template #element>
                                  <div
                                    class="font-sansSerif font-medium text-sm py-1 px-3 text-white rounded-full bg-[#F08232] ml-2"
                                  >
                                    {{ $t(`search.houseStatus.warning`) }}
                                  </div>
                                </template>

                                <template v-if="house.warningReason" #text>
                                  <div class="line-clamp-3">
                                    {{ house.warningReason }}
                                  </div>
                                </template>
                              </BaseTooltip>

                              <div
                                v-if="house.exclusivity"
                                class="font-sansSerif font-medium text-sm py-1 px-3 text-white rounded-full bg-primary-700 ml-2 capitalize"
                              >
                                {{ $t('global.exclusive') }}
                              </div>
                            </div>
                          </template>
                          <template #card-content>
                            <SearchAdminHouseDetail
                              class="px-4 pb-4"
                              :client-fees-rate="house.clientFeesRate"
                              :commission-rate="house.commissionRate"
                              :commission-rate-public-price="
                                house.commissionRatePublicPrice
                              "
                              :concierge-service-offer="
                                house.conciergeServiceOffer
                              "
                              :definitive-prices="house.definitivePrices"
                              :has-operational-costs="house.hasOperationalCosts"
                              :lc-commission="house.lcCommission"
                              :net-owner-commission-rate="
                                house.netOwnerCommissionRate
                              "
                              :operational-costs="house.operationalCosts"
                              :owner-price-without-op-costs="
                                house.ownerPriceWithoutOpCosts
                              "
                              :period-valid="house.periodValid"
                              :price-value="house.currentPrice.value"
                              :price="house.currentPrice.text"
                            />
                            <div
                              class="flex items-center min-h-9 text-sm px-4 bg-primary-100 text-primary-900 font-medium"
                            >
                              {{ house.availabilitiesUpdatedAt }}
                            </div>
                            <SearchAdminHouseDetailLinks
                              v-if="$device.isMobileOrTablet"
                              :house-id="house.id"
                              :private-token="house.privateToken"
                              :slug="house.slug"
                              class="mt-5"
                              @handle-download-pdf="openModalDownloadPdf(house)"
                              @handle-send-comment="
                                openModalAdminHouseRequest(house)
                              "
                            />
                          </template>
                        </SearchHouseCard>
                      </BaseCol>

                      <ClientOnly>
                        <BaseCol
                          v-if="showHouseCardInfo(house.id)"
                          class="mb-4"
                          cols="24"
                          md="12"
                          xxl="8"
                        >
                          <BaseHouseCardInfo />
                        </BaseCol>
                      </ClientOnly>
                    </template>
                  </BaseRow>

                  <SearchListLoadMoreButton
                    :page="page"
                    :show-paginate="showPaginate"
                    @next-page="paginate('next-page')"
                  />
                </template>

                <LazySearchMap
                  v-else-if="!isLoading"
                  :end-date="endDate"
                  :hits-maps="hitsMap"
                  :start-date="startDate"
                  :track-house="() => {}"
                  :user-is-admin="true"
                  :user-is-partner="false"
                  class="search__map"
                  @click-toggle-wishlist="setCurrentHouseFavorite"
                />
              </BaseCol>
            </BaseRow>

            <SearchMapButton
              v-if="isSmallScreen"
              :map-visible="mapVisible"
              :show-map-button="showMapButton"
              @toggle-map="toggleMap"
            />
          </div>
        </div>
      </div>
    </div>

    <SearchFilterModalMobile
      v-model="showFiltersModal"
      :bathrooms-quantity="bathrooms"
      :bedrooms-quantity="bedrooms"
      :capacity-quantity="capacity"
      :facet-filters="facetFilters"
      :facets="facets"
      :loading-facets="isLoading"
      :nb-hits="nbHits"
      @change-facet="refineFacets"
      @clear-all="clearAllFilterModalV2"
      @clear-filter-numeric="clearFilterNumeric"
      @refine-filter-numeric="filteredNumeric"
      @toggle-filters="toggleFiltersModal"
    />

    <SearchProposalCreateModal v-model="showModalProposalCreate" />

    <SearchAdminHouseRequestModal
      v-if="selectedAdminHouse"
      v-model="adminHouseRequestModal"
      :bathrooms="selectedAdminHouse.bathrooms"
      :bedrooms="selectedAdminHouse.bedrooms"
      :capacity="selectedAdminHouse.capacity"
      :destination="selectedAdminHouse.destination"
      :first-photo-url="selectedAdminHouse.firstPhotoUrl"
      :house-id="selectedAdminHouse.id"
      :name="selectedAdminHouse.name"
      @close-modal="closeModalAdminHouseRequest"
    />

    <SearchAdminHousePdfModal
      v-if="selectedAdminHouse"
      v-model="houseDownloadPdfModal"
      :bathrooms="selectedAdminHouse.bathrooms"
      :bedrooms="selectedAdminHouse.bedrooms"
      :capacity="selectedAdminHouse.capacity"
      :destination="selectedAdminHouse.destination"
      :first-photo-url="selectedAdminHouse.firstPhotoUrl"
      :house-id="selectedAdminHouse.id"
      :name="selectedAdminHouse.name"
      :private-token="selectedAdminHouse.privateToken"
      @close-modal="closeModalDownloadPdf"
    />

    <WishlistMultipleModal v-bind="currentHouseFavoriteSelected" />
  </div>
</template>

<style>
@screen md {
  .search-filter .form-select .multiselect__select:before {
    @apply hidden;
  }
}

.search-filter .form-select--mobile {
  @apply block mb-4 w-full;
}

@screen md {
  .search-filter .form-select--mobile {
    @apply hidden;
  }
}
.search-filter .form-select--mobile .dropdown-toggle {
  @apply w-full py-4 px-3;
  top: -1rem;
}
.search-filter .form-select--mobile .dropdown-menu {
  @apply mt-2;
}
.search-filter .form-select--mobile .search-filter__icon {
  @apply hidden;
}

.search__map.base-map {
  height: 70vh;
}

@screen lg {
  .search__map.base-map {
    height: 850px;
  }
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
