import { InputText } from '~/components/BaseForm/core/primitives'

import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const dealHubspot: typeof InputText = {
    ...InputText,
    attr: {
      ...InputText.attr,
      rules: 'required',
      labelTranslateKey: 'stayProposal.search.dealHubspot',
      placeholderTranslateKey: '',
      name: 'dealHubspot',
    },
    containerInputClass: 'w-full',
  }

  return {
    fields: [dealHubspot],
  }
}
