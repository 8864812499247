<script setup lang="ts">
import type { SupportedCurrencies } from 'lc-services/types'
import { getPriceWithCurrency } from '~/helpers/priceHelper'

const { locale } = useI18n()

const props = withDefaults(
  defineProps<{
    currency?: SupportedCurrencies
    definitivePrices?: boolean
    eurPublicPrice?: number | null
    isRefinedByDate?: boolean
    partnerCommissionRate?: number | null
    price?: string
    priceValue?: number | null
  }>(),
  {
    currency: 'EUR',
    definitivePrices: true,
    eurPublicPrice: null,
    isRefinedByDate: false,
    partnerCommissionRate: null,
    price: '',
    priceValue: null,
  },
)

const priceFixed = ref(10000)

const isLessThanOrEqualToThePriceFixed = computed(() => {
  return (
    props.isRefinedByDate &&
    props.eurPublicPrice &&
    props.eurPublicPrice >= priceFixed.value
  )
})
const isMoreThanTheFixedPrice = computed(() => {
  return (
    props.isRefinedByDate &&
    props.eurPublicPrice &&
    props.eurPublicPrice < priceFixed.value
  )
})
const commissionAmount = computed(() => {
  if (props.priceValue && props.partnerCommissionRate) {
    return getPriceWithCurrency({
      price: (props.priceValue * props.partnerCommissionRate) / 100,
      currency: props.currency,
      i18n: { locale },
    })
  }

  return ''
})
</script>

<template>
  <div class="text-md font-bold">
    <p
      class="flex flex-wrap items-center gap-2 mb-2"
      data-testid="partner-price"
    >
      {{ price }}
      <span v-if="isLessThanOrEqualToThePriceFixed" class="text-gray-400">
        {{ $t('search.partnerCom') }}
      </span>

      <span
        v-if="priceValue && !definitivePrices"
        class="flex items-center w-fit bg-error/20 text-error py-1 px-2 rounded-full text-3xs"
      >
        <BaseIcon name="warningTriangle" :size="1.1" class="mr-1 font-bold" />
        {{ $t('house.priceNotValidated') }}
      </span>
    </p>

    <p class="font-normal mb-0" data-testid="partner-commission">
      <span v-if="isMoreThanTheFixedPrice">{{
        $t('search.noPartnerCommissionRate')
      }}</span>
      <span v-else-if="isLessThanOrEqualToThePriceFixed">
        {{
          $t('search.partnerCommissionWithPrice', {
            percent: partnerCommissionRate,
            price: commissionAmount,
          })
        }}
      </span>
      <span v-else>{{
        $t('search.partnerCommissionRate', { count: partnerCommissionRate })
      }}</span>
    </p>
  </div>
</template>
