<script setup lang="ts">
withDefaults(
  defineProps<{
    destination?: string
    firstPhotoUrl?: string
    modelValue: boolean
    name?: string
  }>(),
  {
    destination: '',
    firstPhotoUrl: '',
    name: '',
  },
)
const emits = defineEmits<{
  'close-modal': []
}>()
defineSlots<{ 'image-bottom-right'(): any; 'content'(): any }>()
</script>

<template>
  <BaseModalV2
    :model-value="modelValue"
    active-esc-mode
    size="m"
    @close="emits('close-modal')"
    @update:model-value="emits('close-modal')"
  >
    <template #content>
      <div>
        <BaseOverlayImage :image="{ img: firstPhotoUrl, alt: name }">
          <template #bottom-left>
            <div>
              <h2 class="m-0 text-5xl text-white">
                {{ name }}
              </h2>
              <div class="text-white">
                {{ destination }}
              </div>
            </div>
          </template>

          <template #bottom-right>
            <slot name="image-bottom-right" />
          </template>
        </BaseOverlayImage>

        <div class="mt-4">
          <slot name="content" />
        </div>
      </div>
    </template>
  </BaseModalV2>
</template>
