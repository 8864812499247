<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/forms/search/proposal/create/proposalForm'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'

import type { StateProposal } from '~/types/proposal'

type InitialData = { dealHubspot: string }

const localePath = useLocalePath()
const router = useRouter()
const { t } = useI18n()
const { $api } = useNuxtApp()
const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('stayProposal.search.continueAndCustomize'))
const proposalState = useState<StateProposal>('proposal')

const emits = defineEmits<{
  'update:model-value': [boolean]
}>()
withDefaults(
  defineProps<{
    modelValue?: boolean
  }>(),
  {
    modelValue: false,
  },
)

const groupFieldsForm = reactive(groupFields())
const initialData = reactive<InitialData>({
  dealHubspot: '',
})

const createProposal = (data: {
  automatic_proposal: { deal_hubspot: string }
}) => {
  return $api.v3.userAutomaticProposals.create(data)
}

const mutation = (data: InitialData) => ({
  request: createProposal,
  input: {
    automatic_proposal: {
      deal_hubspot: data.dealHubspot,
    },
  },
})

const onSubmit: () => SendMutationType<{
  data: StateProposal
}>['onSubmit'] = () => ({
  success: (res) => {
    if (res?.data?.state === 'in_progress' && !res?.data?.b2b2c) {
      emits('update:model-value', false)
      proposalState.value = { ...proposalState.value, ...res.data }
    } else {
      router.push(localePath({ name: 'stayProposal-edit' }))
      emits('update:model-value', false)
    }
  },
  error: (err, setFieldError) => {
    const errorMesage =
      err?.errors?.response?.data?.errors?.[0]?.automatic_proposal
    const alreadyAssigned =
      err?.errors?.response?.data?.errors?.[0]?.already_assigned

    if (alreadyAssigned) {
      setFieldError(
        'dealHubspot',
        t('stayProposal.search.errorMessageAlreadyAssigned', {
          fullname: `${alreadyAssigned.first_name} ${alreadyAssigned.last_name}`,
        }),
      )
    } else if (String(errorMesage) === 'deal_not_found') {
      setFieldError('dealHubspot', t('stayProposal.search.errorMessageWrong'))
    } else {
      setFieldError(
        'dealHubspot',
        t('stayProposal.search.errorMessageAlreadyUsed'),
      )
    }
  },
})
</script>

<template>
  <BaseModalV2
    :model-value="modelValue"
    data-cy="modal-proposal"
    size="s"
    @close="$emit('update:model-value', false)"
    @update:model-value="emits('update:model-value', $event)"
  >
    <template #content>
      <div class="mb-10">
        <div class="text-xl font-bold">
          {{ $t('stayProposal.search.newProposal') }}
        </div>
      </div>

      <BaseForm
        ref="proposalForm"
        :active-modal-errors="false"
        :active-modal-save-or-quit="false"
        :button-primary="btnPrimary"
        :disabled-primary-button="!initialData.dealHubspot"
        :group-fields="groupFieldsForm"
        :initial-data="initialData"
        :mutation="mutation"
        :on-submit="onSubmit"
        class="mb-36"
        container-button-class="fixed bottom-0 left-0 w-full p-4 lg:py-6 lg:px-8 flex justify-end border-t border-gray-200 bg-white"
        id-form="travel-partners-form"
        wrapper-button-class="-mt-4 md:m-0"
      />
    </template>
  </BaseModalV2>
</template>
