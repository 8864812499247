<script setup lang="ts">
withDefaults(
  defineProps<{
    bathrooms: number
    bedrooms: number
    capacity: number
    destination: string
    firstPhotoUrl: string
    houseId: number
    modelValue?: boolean
    name: string
    privateToken?: string
  }>(),
  {
    modelValue: false,
    privateToken: '',
  },
)
const emits = defineEmits<{
  'update:model-value': []
  'close-modal': []
}>()
const { userIsAdmin } = useAuth()
</script>

<template>
  <SearchAdminModal
    :destination="destination"
    :first-photo-url="firstPhotoUrl"
    :model-value="modelValue"
    :name="name"
    @close-modal="emits('update:model-value')"
  >
    <template #content>
      <SearchHouseDownloadPdf
        :house-id="houseId"
        :name="name"
        :private-token="privateToken"
        :user-is-admin="userIsAdmin"
        @canceled="emits('close-modal')"
      />
    </template>
  </SearchAdminModal>
</template>
